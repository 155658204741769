import { PURGE } from "redux-persist";
import { Collection } from "types";

type Props = {
  type: string;
  payload: any;
};

const initialState = {
  collections: [],
  patientCollections: [],
};

export default (state = initialState, { type, payload }: Props) => {
  switch (type) {
    case "CREATE_COLLECTION":
      return {
        ...state,
        collections: [...state.collections, payload],
      };
    case "FETCH_COLLECTIONS":
      return {
        ...state,
        collections: [...payload],
      };
    case "FETCH_PATIENT_COLLECTIONS":
      return {
        ...state,
        patientCollections: [...payload],
      };
    case "DELETE_COLLECTION":
      const filteredCollections = state.collections.filter(
        (c: Collection) => c.uuid !== payload
      );
      return {
        ...state,
        collections: [...filteredCollections],
      };
    case "UPDATE_COLLECTION":
      const collectionsWithoutUpdated = state.collections.filter(
        (c: Collection) => c.uuid !== payload.uuid
      );
      return {
        ...state,
        collections: [...collectionsWithoutUpdated, payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
