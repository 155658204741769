/* eslint-disable react-hooks/rules-of-hooks */
import {
  Assignment,
  AssignmentState,
  AssignmentSubmission,
  Field,
  FieldOption,
  Section,
} from "types";
import { createContext, Dispatch, useContext, useReducer } from "react";
import { displayFields } from "../../TherapistPages/AssignmentBuilder/components/AssignmentBuilderMenu/fieldTypes";

const AssignmentStateContext = createContext<AssignmentState>(
  {} as AssignmentState
);
const AssignmentDispatchContext = createContext<
  Dispatch<
    Partial<AssignmentState> & {
      location?: {
        assignmentSlug: string | null;
        sectionSlug: string | null;
      };
    }
  >
>(
  {} as Dispatch<
    Partial<AssignmentState> & {
      location?: {
        assignmentSlug: string | null;
        sectionSlug: string | null;
      };
    }
  >
);
const useAssignmentStoreDefaults = () => {
  const state: AssignmentState = {
    initialized: false,
    assignments: [],
    submissions: [],
    activeAssignment: {} as Assignment,
    activeDraft: {} as AssignmentSubmission,
    draftId: "",
    isPrivate: false,
    activeSection: {} as Section,
    isSectionValid: false,
    activeColumn: "assignments",
    responses: {},
    /**
     * Parse the assignmentSlug and sectionSlug from the url path
     * @returns {assignmentSlug: string | null, sectionSlug: string | null}
     */

    parsedLocation(location: Location): {
      assignmentSlug: string | null;
      sectionSlug: string | null;
    } {
      const path = location.pathname.split("your-assignments/");
      if (path.length <= 1) {
        return {
          assignmentSlug: null,
          sectionSlug: null,
        };
      }
      const params = path[1].split("/");
      const paramZero = params[0];
      const paramOne = params[1];
      const assignmentSlug = paramZero ? decodeURIComponent(paramZero) : null;
      const sectionSlug = paramOne ? decodeURIComponent(paramOne) : null;
      return {
        assignmentSlug,
        sectionSlug,
      };
    },
    isFinalSection() {
      return (
        (this.activeSection &&
          this.activeSection.order ===
            this.activeAssignment?.sections?.length) ||
        false
      );
    },
    getDraft(assignmentId: string) {
      const drafts = this.submissions?.filter(
        (submission) =>
          submission.assignmentId === assignmentId && submission.isDraft
      );
      if (drafts) {
        return drafts[0];
      }
      return;
    },
    getField(fieldId: string) {
      let field;
      this.activeAssignment?.sections?.forEach((section) => {
        field = section.fields.find((field) => field.uuid === fieldId);
      });
      return field;
    },
    getNextSection() {
      if (
        !this.isFinalSection() &&
        this.activeSection &&
        this.activeAssignment
      ) {
        const nextSection = this.activeSection.order + 1;
        if (nextSection <= this.activeAssignment.sections?.length) {
          return this.activeAssignment.sections[nextSection - 1];
        }
      }
      return null;
    },
    getPreviousSection() {
      if (this.activeSection && this.activeSection.order > 1) {
        const prevSection = this.activeSection.order - 1;
        return this.activeAssignment?.sections[prevSection - 1];
      }
      return null;
    },
    getDefaultStartSection() {
      return this.activeAssignment?.sections?.find(
        (section) => section.isDefaultStart
      );
    },
    getLatestSubmission() {
      const submissions = this.submissions.filter(
        (submission) => submission.assignmentId === this.activeAssignment?.uuid
      );
      if (submissions.length > 0) {
        return submissions[0];
      }
      return;
    },
    getPinnedFields() {
      const pinnedContent: {
        title: string;
        content: string | string[];
      }[] = [];
      this.activeAssignment?.sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (
            field.pinToSection &&
            this.activeSection?.name === field.pinToSection
          ) {
            let value = this.responses[field.uuid as string]?.value;
            switch (field.type) {
              case "MULTI_RESPONSE":
                let values: any[];
                if (typeof value === "string") {
                  try {
                    values = JSON.parse(value);
                  } catch (e) {
                    values = [];
                  }
                } else {
                  values = value || [];
                }
                value = values;
                break;
            }

            pinnedContent.push({
              title: field.pinTitle || field.label,
              content: value,
            });
          }
        });
      });
      return pinnedContent;
    },
    validateSection(activeSection: Section) {
      const fields: Field[] = [];
      let isValid = true;
      const validateField = (field: Field) => {
        if (displayFields.includes(field.type)) {
          return field;
        }
        const value = this.responses[field.uuid as string]?.value;
        field.error = false;
        if (field.required && !value) {
          field.error = true;
          isValid = false;
        }
        if (field.type === "MULTI_RESPONSE" && field.required) {
          let values: any[];
          if (typeof value === "string") {
            try {
              values = JSON.parse(value);
            } catch (e) {
              values = [];
            }
          } else {
            values = value || [];
          }
          values?.forEach((val: string) => {
            if (!val) {
              field.error = true;
              isValid = false;
            }
          });
        }
        return field;
      };
      activeSection?.fields.forEach((field) => {
        field = validateField(field);
        fields.push(field);
      });
      activeSection.fields = fields;
      return { validatedSection: activeSection, isValid };
    },
  };
  /**
   * Clear the active assignment from the state object
   * @param state
   */
  const clearActiveAssignment = (state: AssignmentState) => {
    state.activeAssignment = {} as Assignment;
    state.activeSection = {} as Section;
    state.activeDraft = {} as AssignmentSubmission;
    state.draftId = undefined;
    state.isSectionValid = false;
    state.activeColumn = "assignments";
    state.responses = {};
    return state;
  };
  /**
   * Set the active draft in the state object. If there's a draft,
   * set the response fields to the state responses mapped by the fieldId of the assignment
   * @param state
   * @param draft
   */

  const setDraft = (state: AssignmentState, draft?: AssignmentSubmission) => {
    state.activeDraft = draft;
    state.draftId = draft?.uuid;
    if (!state.activeDraft) return state;
    /**
     * Check if the active draft has all the fields of the active assignment.
     * If not, (because the therapist has added new fields to the assignment)
     * add the new fields to the active draft
     */

    const draftFieldIds = state.activeDraft.responseFields.map(
      (field) => field.fieldId
    );
    for (const section of state.activeAssignment?.sections || []) {
      for (const field of section.fields) {
        if (!draftFieldIds.includes(field.uuid as string)) {
          state.activeDraft.responseFields.push({
            fieldId: field.uuid as string,
            value: "",
            fieldType: field.type as any,
            sectionId: field.sectionId as string,
            sectionLabel: section.label,
            fieldLabel: field.label,
          });
        }
      }
    }
    /**
     * Set the state responses to the response fields of the active draft
     */
    state.activeDraft.responseFields.forEach((field) => {
      // graphql returns null as "null" string, so we need to convert it
      // to `undefined` for the input fields
      field.value = field.value === "null" ? undefined : field.value;
      // set the response field to the state responses, using the fieldId (from the assignment)
      // as the key, so we can easily access it when updating changes from user input
      state.responses[field.fieldId] = field;
    });
    return state;
  };
  /**
   * Set the default section in the state object to the first section of the active assignment
   * if there's a default section and there are previous submissions,
   * set the active section to the default section
   * @param state
   */
  const setDefaultSection = (state: AssignmentState) => {
    state.activeColumn = "sections";
    if (!state.activeAssignment) return state;
    state.activeColumn = "fields";
    state.activeSection = state.activeAssignment.sections[0];
    const defaultStartSection = state.getDefaultStartSection();
    const submissions = state.submissions.filter(
      (submission) => submission.assignmentId === state.activeAssignment?.uuid
    );
    if (
      state.activeAssignment.sections.length == 1 ||
      !defaultStartSection ||
      submissions.length == 0
    ) {
      return state;
    }
    state.activeSection = defaultStartSection;
    return state;
  };
  /**
   * Set the active assignment in the state object.
   * This function does the following:
   * 1. Reset activeAssignment, activeSection, activeDraft, draftId, isSectionValid, activeColumn, and responses to empty values
   * 2. Sort the sections by order
   * 3. Sort the fields by order and parse the options if they are strings
   * 4. Check if there's an existing draft for the assignment
   * 5. Set the active draft to the existing draft and draftId to the submission uuid
   * (a submission with isDraft = true)
   * 6. If there's no active draft, generate a response field object
   * for each editable field in the assignment.
   * 7. Finally, set the active assignment to the state
   * @param state
   * @param assignment
   */
  const setAssignment = (state: AssignmentState, assignment?: Assignment) => {
    if (!assignment) return state;
    /**
     * If the active assignment is already set, and it's the same as the assignment
     * being set, return the state early to prevent unnecessary processing. The main use case for this
     * is when the active assignment is being set from the url path
     */
    if (
      state.activeAssignment &&
      state.activeAssignment.uuid === assignment?.uuid
    ) {
      return state;
    }
    /**
     * Clear the active assignment from the state object
     */
    state = clearActiveAssignment(state);
    /**
     * Sort the sections by order
     */
    assignment
      ? (assignment.sections = assignment?.sections.sort(
          (a, b) => a.order - b.order
        ))
      : null;
    /**
     * Sort the fields by order and parse the options if they are strings
     * Note: this is where any other field properties can be parsed in preparation for the state
     * THERE ARE NO OTHER PLACES IN THE CODE WHERE THE ASSIGNMENT FIELDS ARE PARSED
     */
    assignment.sections = assignment.sections.map((section) => {
      // sort the fields by order
      section.fields = section.fields.sort((a, b) => a.order - b.order);
      // parse the options if they are strings
      section.fields = section.fields.map((field) => {
        const options = field.options as unknown as FieldOption[] | string;
        if (typeof options === "string") {
          field.options = JSON.parse(options) as FieldOption[];
        }
        return field;
      });
      return section;
    });
    /**
     * Now we're done preparing the assignment, set the active assignment to the state
     * We will reference state.activeAssignment in the next steps
     */
    state.activeAssignment = assignment;
    /** set the active column to "sections" since now we have an active assignment.
     * This is to ensure that the sections column is active when the assignment is set
     * This will be overridden if the section is set from the url path or from the reducer
     */
    state.activeColumn = "sections";
    /**
     * Check if there's an existing draft for the assignment
     * Set the active draft to the existing draft and draftId to the submission uuid
     * (a submission with isDraft = true)
     */
    const draft = state.getDraft(assignment.uuid as string);
    state = setDraft(state, draft);
    /**
     * If there's no active draft, generate a response field object
     * for each editable field in the assignment.
     * This conditional is used instead of the else block for the sake of readability
     */
    if (!state.activeDraft) {
      /**
       * Since there's not an active draft, we will check if the therapist has set a default start section,
       * and prefill the fields of the previous sections from the latest submission.
       *
       * We will also prefill the fields that are marked to use the previous value
       */
      const defaultSection = state.getDefaultStartSection();
      const latestSubmission = state.getLatestSubmission();
      if (latestSubmission) {
        if (defaultSection) {
          state.activeSection = defaultSection;
          state.activeColumn = "fields";
        }
        const fieldIds: string[] = [];
        state.activeAssignment?.sections.map((section) => {
          if (defaultSection) {
            // prefill the fields of the previous sections
            if (section.order < defaultSection.order) {
              section.fields.forEach((field) => {
                if (!displayFields.includes(field.type)) {
                  fieldIds.push(field.uuid as string);
                }
              });
            }
          }
          // prefill the fields that are marked to use the previous value
          section.fields.forEach((field) => {
            if (field.usePrevious) {
              if (!fieldIds.includes(field.uuid as string)) {
                fieldIds.push(field.uuid as string);
              }
            }
          });
        });
        latestSubmission.responseFields.forEach((field) => {
          if (fieldIds.includes(field.fieldId)) {
            /*TODO: What happens with the pre-filled date field from the first section??? */
            // we need to delete the uuid from the response field object
            // so that it's not sent to the server when the new submission is created
            delete field.uuid;
            field.preFilled = true;
            state.responses[field.fieldId] = field;
          }
        });
      }
      assignment.sections.forEach((section) => {
        section.fields.forEach((field) => {
          // if the field type is not in the displayFields array it means it's an editable field,
          // if there's not already a response, we generate a response field object for it.
          if (!displayFields.includes(field.type)) {
            let value = "";
            if (field.type === "DATE") {
              value = new Date().toISOString().split("T")[0];
            }
            if (field.type === "SCALE") {
              value = "5";
            }
            if (!state.responses[field.uuid as string]) {
              state.responses[field.uuid as string] = {
                value: value,
                fieldType: field.type as any,
                sectionId: field.sectionId as string,
                fieldId: field.uuid as string,
                sectionLabel: section.label,
                fieldLabel: field.label,
              };
            }
          }
        });
      });
    }
    return state;
  };
  const setSection = (state: AssignmentState, section?: Section) => {
    // Don't set the section if it's already set. Mainly to prevent overwriting the field.error values
    // when the section is being set from url
    if (state.activeSection && state.activeSection.uuid === section?.uuid)
      return state;
    state.activeSection = section;
    return state;
  };
  const stateReducer = (
    state: AssignmentState,
    action: Partial<AssignmentState> & {
      location?: {
        assignmentSlug: string | null;
        sectionSlug: string | null;
      };
    }
  ) => {
    const keys = Object.keys(action) as ("location" | keyof AssignmentState)[];
    /**
     * This is to an implementation to set the state from the url path
     * It's only called in 'AssignmentsForPatients.tsx'
     * after validating there that the assignment or section slug is different from the active one.
     */
    if (keys.includes("location")) {
      if (state.assignments.length === 0 || !action.location) {
        return state;
      }
      const { sectionSlug, assignmentSlug } = action.location;
      if (!assignmentSlug) {
        state = clearActiveAssignment(state);
        return state;
      }
      if (assignmentSlug) {
        if (
          !state.activeAssignment ||
          state.activeAssignment.slug !== assignmentSlug
        ) {
          const assignment = state.assignments?.find(
            (assignment) => assignment.slug === assignmentSlug
          );
          state.activeSection = {} as Section;
          state = setAssignment(state, assignment);
        }
      }
      if (sectionSlug) {
        state.activeSection = state.activeAssignment?.sections?.find(
          (section) => section.slug === sectionSlug
        );
        state.activeColumn =
          state.activeSection === undefined ? "sections" : "fields";
      } else {
        state = setDefaultSection(state);
      }
      return state;
    }
    /**
     * This is only used in the 'AssignmentContextWrapper.tsx' to set
     * the assignments and submissions from a fetch call
     */
    if (keys.includes("assignments") && keys.includes("submissions")) {
      const submissions = action.submissions?.sort(
        (a, b) => b.createdAt - a.createdAt
      );
      state = {
        ...state,
        submissions: submissions || [],
        assignments: action.assignments || [],
        initialized: true,
      };
      state = initState(state);
      return state;
    }
    /**
     * The order of these checks is IMPORTANT. This is set after the submission is saved.
     * Locally built array with the new submission to save on a server call.
     */
    if (keys.includes("submissions")) {
      state = { ...state, submissions: action.submissions || [] };
      delete action.submissions;
    }
    if (keys.includes("activeAssignment")) {
      state = setAssignment(state, action.activeAssignment);
      // return early with the active assignment set.
      // This is to protect from overwriting params from the action object in the later return statement
      return state;
    }
    if (keys.includes("activeDraft")) {
      state = setDraft(state, action.activeDraft);
      // delete the activeDraft from the action object to prevent overwriting
      // the state in the return statement. We don't return early here
      // because there may be other params in the action object
      delete action.activeDraft;
    }
    if (keys.includes("activeSection")) {
      if (!keys.includes("activeColumn")) {
        action.activeColumn = "fields";
      }
    }
    return { ...state, ...action };
  };
  /**
   * Set the assignment and section from the url path
   * @param stateObject
   */
  const updateStateFromPath = (stateObject: AssignmentState) => {
    const { sectionSlug, assignmentSlug } = stateObject.parsedLocation(
      window.location
    );
    if (assignmentSlug) {
      stateObject.activeColumn = "sections";
      const assignment = stateObject.assignments?.find(
        (assignment) => assignment.slug === assignmentSlug
      );
      stateObject = setAssignment(stateObject, assignment);
      if (sectionSlug) {
        const foundSection = stateObject.activeAssignment?.sections?.find(
          (section) => section.slug === sectionSlug
        );
        stateObject = setSection(stateObject, foundSection);
        stateObject.activeColumn = "fields";
      }
      if (!sectionSlug) {
        stateObject = setDefaultSection(stateObject);
      }
    }
    return stateObject;
  };
  /**
   * This function initializes the state object, and guards against setting the state if there are no assignments.
   * The assignments and submissions are being fetched in 'AssignmentContextWrapper.tsx',
   * so the state is actually initialized twice: once with an empty array, and then with the fetched data.
   * it's happening outside the context provider so it's effectively a brand-new
   * state object once the assignments and submissions are dispatched.
   */
  const initState = (state: AssignmentState) => {
    if (state.assignments.length === 0) {
      return state;
    }
    state = updateStateFromPath(state);
    return state;
  };
  const [assignmentState, setAssignmentState] = useReducer(stateReducer, state);
  return {
    assignmentState,
    setAssignmentState,
    initState,
  };
};
const useAssignmentStore = () => {
  const state = useContext(AssignmentStateContext);
  const dispatch = useContext(AssignmentDispatchContext);
  return { state, dispatch };
};
export {
  useAssignmentStoreDefaults,
  useAssignmentStore,
  AssignmentStateContext,
  AssignmentDispatchContext,
};
