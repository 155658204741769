import React, { useCallback, useEffect, useState } from "react";
import "./AssignmentsForPatientsFieldsColumn.styles.scss";
import { useAssignmentStore } from "../../assignmentStore";
import { DisplayFields, EditableFields } from "../../fields";
import { Field } from "types";
import HintAndError from "../../fields/CommonComponents/HintAndError";
import {
  displayFields,
  editFields,
} from "../../../../TherapistPages/AssignmentBuilder/components/AssignmentBuilderMenu/fieldTypes";
import { createSubmission, updateSubmission } from "../../useSubmissionsApi";
import AssignmentsForPatientsDesktopFooter from "../AssignmentsForPatientsDesktopFooter/AssignmentsForPatientsDesktopFooter";
import { usePinnedFields, useViewport } from "utils/hooks";
import MobileFooter from "../MobileFooter/MobileFooter";
import { useNavigate } from "react-router-dom";
import { Collapse, MobilePageHeader, PinnedField } from "components";
import CloseIcon from "assets/icons/xmark.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AssignmentsForPatientsFieldsColumn = () => {
  const { t } = useTranslation(["common"]);
  const { state, dispatch } = useAssignmentStore();
  const [isDirty, setIsDirty] = React.useState(false);
  const { activeSection } = state;
  const isFinalSection = state.isFinalSection();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { isDesktop } = useViewport();
  const history = useNavigate();
  const saveSubmission = useCallback(
    async (isDraft = true) => {
      const responseFields = Object.keys(state.responses).map((key) => {
        const responseField = state.responses[key];
        // don't want to send preFilled to the server!! Also, this will remove
        // the preFilled indicator for the user as soon as they edit a field
        delete responseField.preFilled;
        return responseField;
      });
      //check if there's an existing draft
      if (!state.draftId) {
        return await createSubmission(
          {
            assignmentId: state.activeAssignment?.uuid || "",
            assignmentLabel: state.activeAssignment?.label || "",
            privacy: state.isPrivate,
            isDraft: isDraft,
          },
          responseFields
        );
      } else {
        return await updateSubmission(
          {
            uuid: state.draftId,
            assignmentId: state.activeAssignment?.uuid || "",
            assignmentLabel: state.activeAssignment?.label || "",
            privacy: state.isPrivate,
            isDraft: isDraft,
          },

          responseFields
        );
      }
    },
    [state]
  );
  const { pinnedFields } = usePinnedFields({
    currentAssignment: state.activeAssignment,
    currentDraft: state.activeDraft,
    currentSection: state.activeSection,
  });
  const pinnedContent = state.getPinnedFields();
  const [collapsed, setCollapsed] = useState(false);

  // Open pinned fields on each section
  useEffect(() => setCollapsed(false), [activeSection]);
  useEffect(() => {
    const interval = setInterval(async () => {
      if (isDirty) {
        await saveSubmission();
        setIsDirty(false);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [saveSubmission, isDirty]);
  if (!activeSection) return <></>;

  const handleChange = (field: Field, index: number, value: any) => {
    if (state.responses[field.uuid as string]?.value !== value) {
      setIsDirty(true);
    }
    state.responses[field.uuid as string].value = value;
    state.responses[field.uuid as string].preFilled = false;
    activeSection.fields[index].error = false;
    dispatch({ activeSection, responses: state.responses });
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const { validatedSection, isValid } = state.validateSection(activeSection);
    dispatch({ activeSection: validatedSection, isSectionValid: isValid });
    if (!isValid) {
      toast.warning("Please fill out all required fields before proceeding");
      return;
    }
    setIsSubmitting(true);
    const submission = await saveSubmission(!isFinalSection);
    dispatch({ activeDraft: submission });
    let hasSubmission = false;
    state.submissions.forEach((sub, index) => {
      if (sub.uuid === submission.uuid) {
        state.submissions[index] = submission;
        hasSubmission = true;
      }
    });
    if (!hasSubmission) {
      state.submissions.push(submission);
    }

    dispatch({ submissions: state.submissions });
    setIsSubmitting(false);

    if (!isFinalSection) {
      toast(`Your ${state.activeAssignment?.label} draft has been saved!`);
      const nextSection = state.getNextSection();
      if (nextSection) {
        history(
          `/your-assignments/${state.activeAssignment?.slug}/${nextSection.slug}`
        );
      }
    } else {
      toast(`Your ${state.activeAssignment?.label} has been submitted!`);
      history("/your-submissions");
    }
  };

  const getTitle = () => {
    let title;
    if (
      state.activeAssignment?.sections?.length === 1 &&
      activeSection?.label === "Page 1"
    ) {
      title = state.activeAssignment.label;
    } else {
      title = activeSection?.label;
    }

    return title;
  };

  return (
    <>
      <MobilePageHeader
        title={getTitle()}
        rightAction={() => {
          history(`/your-assignments/`);
        }}
        rightIcon={CloseIcon}
      />
      <form onSubmit={submitForm}>
        {pinnedFields && pinnedFields.length > 0 && (
          <div className="collapse-box-for-patients-wrapper">
            <div className="collapse-box-for-patients">
              <Collapse
                title="Expand to see your previous response"
                content={pinnedFields}
                collapsed={collapsed}
                onCollapsed={(value: boolean) => setCollapsed(value)}
              />
            </div>
          </div>
        )}
        {pinnedContent && pinnedContent.length > 0 && (
          <div className="collapse-box-for-patients-wrapper">
            <div className="collapse-box-for-patients">
              {pinnedContent.map((content, index) => {
                return (
                  <PinnedField key={index} title={content.title}>
                    {Array.isArray(content.content) ? (
                      content.content.map((item, index) => (
                        <div key={index}>
                          <span>{`${index + 1}. `}</span>
                          {item}
                        </div>
                      ))
                    ) : (
                      <div>{content.content}</div>
                    )}
                  </PinnedField>
                );
              })}
            </div>
          </div>
        )}
        <div className="fields-box-for-patients-wrapper">
          <div className="fields-for-patients-box">
            <div className="light-text fields-for-patients-description">
              {activeSection?.description}
            </div>

            {activeSection?.fields?.map((field, index) => {
              if (field.status === "HIDDEN") {
                return null;
              }
              if (displayFields?.includes(field.type)) {
                return <DisplayFields key={field.uuid} field={field} />;
              }
              if (editFields?.includes(field.type)) {
                return (
                  <div className="editable-field" key={field.uuid}>
                    <EditableFields
                      field={field}
                      defaultValue={
                        state.responses[field.uuid as string]?.value
                      }
                      onChange={(value) => handleChange(field, index, value)}
                    />

                    {state.responses[field.uuid as string]?.preFilled && (
                      <div
                        className={`pre-filled-field mt-2 ${
                          state.responses[field.uuid as string]?.preFilled &&
                          "show"
                        }`}
                      >
                        {t("patient_assignments.pre_filled_field")}
                      </div>
                    )}

                    <HintAndError
                      error={field.error}
                      hint={
                        field.hint && field.hint !== "null" ? field.hint : ""
                      }
                    />
                  </div>
                );
              }
              return (
                <div className="fields-for-patients-box" key={field.uuid}>
                  <div className="light-text fields-for-patients-label">
                    {field.label}
                  </div>
                  <div className="fields-for-patients-value">{field.value}</div>
                </div>
              );
            })}
          </div>
        </div>
        <MobileFooter
          isInfosheet={state.activeAssignment?.category === "INFOSHEET"}
          isFinalSection={isFinalSection}
          isPrivate={state.isPrivate}
          onPrivacyChange={(value: boolean) => {
            dispatch({ isPrivate: value });
          }}
          onBackClick={() => {
            const previousSection = state.getPreviousSection();

            if (previousSection) {
              history(
                `/your-assignments/${state.activeAssignment?.slug}/${previousSection.slug}`
              );
              return;
            }

            history("/your-assignments");
          }}
          showBackButton={activeSection.order > 1}
          isSubmitting={isSubmitting}
        ></MobileFooter>
        {isDesktop && (
          <AssignmentsForPatientsDesktopFooter
            isInfosheet={state.activeAssignment?.category === "INFOSHEET"}
            isFinalSection={isFinalSection}
            isPrivate={state.isPrivate}
            onPrivacyChange={(value: boolean) => {
              dispatch({ isPrivate: value });
            }}
            isSubmitting={isSubmitting}
          />
        )}
      </form>
    </>
  );
};

export default AssignmentsForPatientsFieldsColumn;
