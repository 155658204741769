import { useDispatch } from "react-redux";
import { fetchNotesAction, fetchGroupNotesAction } from "store/actions/notes";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";

const useFetchNotes = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchNotes = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getNotesByTherapistId {
                uuid
                date
                body
                referenceUrl
                patientId
                createdAt
                updatedAt
                author
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchNotesAction(resData.data.getNotesByTherapistId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchGroupNotes = (groupId?: string) => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getNotesByGroupId(groupId: "${groupId}") {
                uuid
                date
                body
                referenceUrl
                patientId
                groupId
                createdAt
                updatedAt
                author
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchGroupNotesAction(resData.data.getNotesByGroupId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    fetchNotes,
    fetchGroupNotes,
  };
};

export default useFetchNotes;
