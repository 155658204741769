import React from "react";
import { UseFormMethods } from "react-hook-form";
import { Select, Avatar, IconButton } from "components";
import { useGetPatients, useGetGroupUsers } from "utils/hooks";
import { Patient, User } from "types";
import { PatientOption } from "../../types/types";
import "./AddExistingSelector.styles.scss";
import { truncateText } from "utils/helpers";
import { useTranslation } from "react-i18next";
interface AddExistingSelectorProps {
  formMethods: UseFormMethods;
  selectedPatients: PatientOption[] | null;
  onAddPatient: (patients: PatientOption[]) => void;
  onRemovePatient: (removedPatient: PatientOption) => void;
  groupId: string;
}

const AddExistingSelector = ({
  formMethods,
  selectedPatients,
  onAddPatient,
  onRemovePatient,
  groupId,
}: AddExistingSelectorProps) => {
  const { t } = useTranslation(["common"]);
  const { patients } = useGetPatients({});
  const { groupUsers } = useGetGroupUsers({ groupId, sort: false });
  const { control, errors } = formMethods;

  const filteredPatients = patients.filter(
    (patient: Patient) =>
      !groupUsers.some((groupUser: User) => groupUser.email === patient.email)
  );

  const patientOptions = filteredPatients.map((patient: Patient) => {
    return {
      value: patient,
      label:
        patient.firstName === null && patient.lastName === null
          ? patient.email
          : `${patient.firstName} ${patient.lastName}`,
    };
  });

  return (
    <div className="add-existing-selector">
      <Select
        name="patients"
        label={t("groups.add_member_panel.choose_patient_label")}
        options={patientOptions}
        rules={{
          required: t("groups.add_member_panel.required_patient_error"),
        }}
        forwardControl={control}
        placeholder={t("groups.add_member_panel.choose_patient_placeholder")}
        isMulti={true}
        className="add-existing-selector__select"
        controlShouldRenderValue={false}
        error={errors?.patients?.message}
        onChange={onAddPatient}
        defaultValue={selectedPatients}
      />
      {selectedPatients && selectedPatients.length > 0 && (
        <div className="add-existing-selector__patients">
          {selectedPatients.map((patient: PatientOption) => (
            <div key={patient.value.email}>
              <div className="add-existing-selector__patient">
                <div className="group-member-panel__avatar-icon">
                  <Avatar user={patient?.value} />
                </div>
                {truncateText(patient?.label, 45)}
                <IconButton
                  icon="CloseCrossBold"
                  onClick={() => onRemovePatient(patient)}
                  extraClass="add-existing-selector__remove-btn"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddExistingSelector;
