/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./MobileFooter.styles.scss";
import React from "react";
import { FlatButton, Icon } from "components";
import BackArrow from "assets/icons/chevron-left.svg";
import { useViewport } from "utils/hooks";

import { useTranslation } from "react-i18next";
import PatientSubmissionPrivacySetting from "../PatientSubmissionPrivacySetting";

interface Props {
  isSubmitting: boolean;
  isFinalSection: boolean;
  onPrivacyChange: (value: boolean) => void;
  showBackButton: boolean;
  onBackClick: () => void;
  isPrivate: boolean;
  isInfosheet: boolean;
}
const MobileFooter = ({
  isSubmitting,
  isFinalSection,
  onPrivacyChange,
  showBackButton,
  onBackClick,
  isPrivate,
  isInfosheet,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const { isMobile } = useViewport();
  if (!isMobile || isInfosheet) {
    return <></>;
  }
  return (
    <div className="patient-section-footer-nav">
      <div className="patient-section-footer-nav__back-button-wrapper">
        {showBackButton && (
          <div
            className="patient-section-footer-nav__back-button"
            onClick={onBackClick}
          >
            <div className="patient-section-footer-nav__back-button--icon">
              <Icon src={BackArrow} />
            </div>
            Back
          </div>
        )}
      </div>
      <PatientSubmissionPrivacySetting
        onPrivacySettingChange={onPrivacyChange}
        isPrivate={isPrivate}
      />

      <FlatButton disabled={isSubmitting} isLoading={isSubmitting}>
        {isFinalSection
          ? t("common_labels.submit_button")
          : t("common_labels.save_and_continue_button")}
      </FlatButton>
    </div>
  );
};

export default MobileFooter;
