import "./AssignmentsForPatientsDesktopFooter.styles.scss";
import React from "react";
import { FlatButton } from "components";
import { useTranslation } from "react-i18next";
import PatientSubmissionPrivacySetting from "../PatientSubmissionPrivacySetting";

interface Props {
  isSubmitting: boolean;
  isFinalSection: boolean;
  onPrivacyChange: (value: boolean) => void;
  isPrivate: boolean;
  isInfosheet: boolean;
}
const AssignmentsForPatientsDesktopFooter = ({
  isSubmitting,
  isFinalSection,
  onPrivacyChange,
  isPrivate,
  isInfosheet,
}: Props) => {
  const { t } = useTranslation(["common"]);
  if (isInfosheet) {
    return <></>;
  }
  return (
    <div className="assignments-for-patients-desktop-footer">
      <PatientSubmissionPrivacySetting
        onPrivacySettingChange={onPrivacyChange}
        isPrivate={isPrivate}
      />

      <FlatButton disabled={isSubmitting} isLoading={isSubmitting}>
        {isFinalSection
          ? t("common_labels.submit_button")
          : t("common_labels.save_and_continue_button")}
      </FlatButton>
    </div>
  );
};

export default AssignmentsForPatientsDesktopFooter;
